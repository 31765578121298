<template>
  <div class="footer-nav-item-container">
    <nav>
      <ul
        v-if="menu"
        class="footer-nav-container">
        <template
          v-for="item in menu.menuItems.nodes"
          :key="item.id">
          <li :class="item.cssClasses.toString()">
            <template v-if="isExternal(item.url)">
              <a
                :href="item.uri"
                :target="item.target">
                {{ item.label }}
              </a>
            </template>
            <template v-else>
              <quick-link
                :to="item.uri"
                :title="item.label">
                {{ item.label }}
              </quick-link>
            </template>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'

const { isExternal } = useUtils()
const store = useStore()
const props = defineProps(['name'])
const menu = ref(store.menu({ name: props.name }))
</script>

<style lang="scss">
.footer-nav-container {
  li {
    line-height: 1;
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
