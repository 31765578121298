<template>
  <transition>
    <footer
      v-if="site"
      id="site-footer"
      class="site-footer">
      <div class="footer-wrapper">
        <div class="footer-info">
          <div class="footer-top">
            <div class="footer-col">
              <FooterMenu name="footer-menu" />
            </div>
            <div class="footer-col">
              <div class="footer-address-socials">
                <div class="socials">
                  <a
                    v-if="site.social_x"
                    :href="site.social_x"
                    target="_blank"
                    class="social twitter">
                    <img
                      src="@/assets/img/logo-x.svg"
                      alt="X Logo" />
                  </a>
                  <a
                    v-if="site.social_linkedin"
                    :href="site.social_linkedin"
                    target="_blank"
                    class="social linkedin">
                    <img
                      src="@/assets/img/logo-linkedin.svg"
                      alt="LinkedIn Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="footer-col">
              <div class="copyright">
                Rein Therapeutics<sup>TM</sup> {{ currentYear }}
              </div>
            </div>
            <div class="footer-col">
              <FooterMenu name="legal-menu" />
            </div>
          </div>
        </div>
        <div class="footer-logo">
          <router-link
            :to="'/'"
            title="Home">
            <img
              v-if="site.site_logo_footer"
              :src="site.site_logo_footer.url"
              alt="Rein Therapeutics Logo" />
          </router-link>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, watch } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useSiteLoading from '@/composables/useSiteLoading'

const { siteLoading } = useSiteLoading()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const site = ref(store.acfOptions())

const currentYear = computed(() => {
  return new Date().getFullYear()
})

watch(siteLoading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})
</script>

<style lang="scss">
.site-footer {
  position: relative;
  padding: 100px 55px;
  color: $white;
  background-color: $charcoal;

  @include tablet-landscape(1) {
    padding: 40px 24px;
  }

  .footer-wrapper {
    display: flex;
    justify-content: space-between;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .footer-info {
      flex: 1;

      @include tablet-landscape(1) {
        order: 1;
      }
    }

    .footer-top,
    .footer-bottom {
      display: flex;

      @include tablet-landscape(1) {
        flex-direction: column;
      }

      .footer-col {
        flex: 1;

        @include tablet-landscape(1) {
          margin: 0 0 30px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .footer-top {
      margin: 0 0 140px;

      @include tablet-landscape(1) {
        margin: 0 0 60px;
      }

      .footer-address-socials {
        .address {
          margin: 0 0 50px;
        }

        .socials {
          display: flex;

          a {
            display: block;
            margin-right: 16px;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .footer-bottom {
      align-items: flex-end;

      @include tablet-landscape(1) {
        align-items: flex-start;
      }

      .footer-col {
        &:first-child {
          @include tablet-landscape(1) {
            order: 2;
            margin: 30px 0 0;
          }
        }
      }
    }

    .footer-logo {
      margin-left: 10%;

      @include tablet-landscape {
        margin-left: 5%;
      }

      @include tablet-landscape(1) {
        order: 0;
        margin: 0 0 60px;
      }

      a {
        display: block;

        img {
          width: 321px;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
