<template>
  <div
    ref="intro"
    class="intro">
    <div class="animation-container">
      <div
        ref="animation"
        class="intro-animation"></div>
      <img
        ref="logo"
        class="logo"
        :src="site.site_logo?.url"
        alt="Rein Therapeutics Logo" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json'
import useIntroShown from '@/composables/useIntroShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'

const store = useStore()
const site = ref(store.acfOptions())

const { setIntroComplete } = useIntroShown()
const { locoScroll } = useSmoothScroll()
const animation = ref()
const logo = ref()

let mm = gsap.matchMedia()

const tl = gsap.timeline({
  paused: true,
  onComplete: () => {
    setComplete()
  },
})

const logoAnimation = () => {
  mm.add('(min-width: 1024px)', () => {
    tl.to('.intro-animation', 1, {
      width: 780,
      ease: 'power1.inOut',
    })
      .to(
        '.animation-container',
        0.5,
        {
          top: 26,
          yPercent: 0,
          y: 0,
          ease: 'power1.inOut',
        },
        0.5
      )
      .to(
        '.intro',
        1,
        {
          backgroundColor: 'transparent',
          ease: 'none',
        },
        0.7
      )
      .to(
        '.intro-animation',
        0,
        {
          autoAlpha: 0,
        },
        1
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 1,
        },
        1
      )
      .to(
        '.animation-container',
        0.5,
        {
          left: -205,
          xPercent: 0,
          x: 0,
        },
        1.2
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 0,
        },
        1.7
      )
  })

  mm.add('(min-width: 768px) and (max-width: 1023px)', () => {
    tl.to('.intro-animation', 1, {
      width: 598,
      ease: 'power1.inOut',
    })
      .to(
        '.animation-container',
        0.5,
        {
          top: 30,
          yPercent: 0,
          y: 0,
          ease: 'power1.inOut',
        },
        0.5
      )
      .to(
        '.intro',
        1,
        {
          backgroundColor: 'transparent',
          ease: 'none',
        },
        0.7
      )
      .to(
        '.intro-animation',
        0,
        {
          autoAlpha: 0,
        },
        1
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 1,
        },
        1
      )
      .to(
        '.animation-container',
        0.5,
        {
          left: -144,
          xPercent: 0,
          x: 0,
        },
        1.2
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 0,
        },
        1.7
      )
  })

  mm.add('(max-width: 767px)', () => {
    tl.to('.intro-animation', 1, {
      width: 448,
      ease: 'power1.inOut',
    })
      .to(
        '.animation-container',
        0.5,
        {
          top: 1,
          yPercent: 0,
          y: 0,
          ease: 'power1.inOut',
        },
        0.5
      )
      .to(
        '.intro',
        1,
        {
          backgroundColor: 'transparent',
          ease: 'none',
        },
        0.7
      )
      .to(
        '.intro-animation',
        0,
        {
          autoAlpha: 0,
        },
        1
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 1,
        },
        1
      )
      .to(
        '.animation-container',
        0.5,
        {
          left: -137,
          xPercent: 0,
          x: 0,
        },
        1.2
      )
      .to(
        '.logo',
        0,
        {
          autoAlpha: 0,
        },
        1.7
      )
  })
}

const setComplete = () => {
  locoScroll.start()
  document.documentElement.classList.remove('no-overflow')
  setIntroComplete()
}

onMounted(() => {
  // setIntroComplete()
  logoAnimation()
  locoScroll.stop()
  document.documentElement.classList.add('no-overflow')

  const anim = LottieWeb.loadAnimation({
    container: animation.value,
    renderer: 'svg',
    autoplay: true,
    loop: false,
    animationData: introJson,
  })
  anim.setSpeed(1.3)

  anim.onComplete = () => {
    tl.play()
  }
})

onBeforeUnmount(() => {
  LottieWeb.destroy
  tl.kill()
})
</script>

<style lang="scss">
.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background-color: $black;

  .animation-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;

    .intro-animation {
      position: relative;
      width: 1200px;
      left: -39px;

      @include tablet-landscape(1) {
        left: -30px;
      }

      @include mobile {
        width: 1000px;
        left: -22px;
        top: 2px;
      }
    }

    img {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 260px;
      transform: translate(-50%, -50%);

      @include tablet-landscape(1) {
        width: 200px;
      }

      @include mobile {
        width: 150px;
      }
    }
  }
}
</style>
