<template>
  <li class="menu-item">
    <template v-if="isExternal(item.url)">
      <div class="link-wrapper">
        <a
          class="font-large"
          :href="item.uri"
          :target="item.target">
          <span>0{{ idx + 1 }}</span>
          <p>{{ item.label }}</p>
        </a>
      </div>
    </template>
    <template v-else>
      <div class="link-wrapper">
        <quick-link
          class="font-large"
          :to="convertToRelative(item.uri)"
          exact>
          <span> 0{{ idx + 1 }}</span>
          <p>{{ item.label }}</p>
        </quick-link>
      </div>
    </template>
  </li>
</template>

<script setup>
import useUtils from '@/composables/useUtils.js'

const { convertToRelative, isExternal } = useUtils()
defineProps(['item', 'idx'])
</script>

<style lang="scss">
.menu-item {
  display: flex;
  border-right: 1px solid $charcoal;
  border-bottom: 1px solid $charcoal;

  @include mobile {
    border-right: none !important;
    border-bottom: 1px solid $charcoal;
  }

  &:nth-child(3n) {
    border-right: none;
  }

  &:nth-last-child(-n + 3) {
    border-bottom: none;

    @include mobile {
      border-bottom: 1px solid $charcoal;
    }
  }

  &:nth-child(-n + 3) {
    border-bottom: 1px solid $charcoal;
  }

  &:last-child {
    @include mobile {
      border-bottom: none;
    }
  }

  .link-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px 35px;
    border-radius: 5px;
    background-color: transparent;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    transition: background-color 0.3s ease-in-out;

    @media only screen and (max-width: 1200px) {
      padding: 30px;
    }

    @include mobile {
      padding: 15px;
    }

    &:hover,
    &.router-link-active {
      background-color: $white;
    }

    p {
      flex: 1;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
    line-height: 1;
    margin-right: 30px;
    border-radius: 50%;
    color: $orange;
    border: 1px solid $orange;
  }
}
</style>
