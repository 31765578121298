<template>
  <header
    id="header-nav"
    class="header-nav"
    :class="navState.navClass">
    <div class="header-wrapper">
      <div class="logo-wrapper">
        <quick-link
          class="font-large"
          :to="'/'"
          exact
          :class="{ visible: introShown }">
          <img
            v-if="site.site_logo?.url"
            :src="site.site_logo?.url || ''"
            alt="Rein Therapeutics Logo"
            class="default" />
          <img
            src="@/assets/img/logo-rein-inverted.svg"
            alt="Rein Therapeutics Logo"
            class="alt" />
        </quick-link>
      </div>
      <div
        v-show="navOpen"
        class="nav-wrapper">
        <div class="nav-head">
          <div class="logo">
            <quick-link
              class="font-large"
              :to="'/'"
              exact
              @click="toggleNav">
              <img
                v-if="site.site_logo_nav?.url"
                :src="site.site_logo_nav?.url || ''"
                alt="Rein Therapeutics Logo" />
            </quick-link>
          </div>
        </div>
        <nav>
          <ul>
            <template
              v-for="(item, index) in menu.menuItems.nodes"
              :key="item.id">
              <NavMenuItem
                :idx="index"
                :item="item"
                @click="toggleNav" />
            </template>
          </ul>
        </nav>
        <div class="nav-footer">
          <div class="socials">
            <a
              v-if="site.social_x"
              :href="site.social_x"
              target="_blank"
              class="social twitter">
              <img
                src="@/assets/img/logo-x.svg"
                alt="X Logo" />
            </a>
            <a
              v-if="site.social_linkedin"
              :href="site.social_linkedin"
              target="_blank"
              class="social linkedin">
              <img
                src="@/assets/img/logo-linkedin.svg"
                alt="LinkedIn Logo" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="hamburger"
        :class="{ open: navOpen }"
        @click="toggleNav">
        <div class="hamburger-inner">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import NavMenuItem from './NavMenuItem.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import { useNavStore } from '@/stores/navState.js'
import useIntroShown from '@/composables/useIntroShown.js'
import { gsap } from 'gsap'

const store = useStore()
const navState = useNavStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const navOpen = ref(false)
const { introShown } = useIntroShown()

const tl = gsap.timeline({
  paused: true,
  onReverseComplete: () => {
    navOpen.value = false
  },
})

const toggleNav = () => {
  if (navOpen.value) {
    tl.reverse()
  } else {
    navOpen.value = true
    tl.play()
  }
}

const closeNav = () => {
  tl.reverse()
}

const navAnimation = () => {
  tl.to('.nav-wrapper', 0.3, {
    autoAlpha: 1,
  }).staggerTo(
    '.menu-item a',
    0.2,
    {
      y: 0,
      autoAlpha: 1,
    },
    0.08
  )
}

const escClose = (e) => {
  if (e.keyCode === 27) {
    closeNav()
  }
}

onMounted(() => {
  document.addEventListener('keyup', escClose, { passive: true })
  navAnimation()
})

onBeforeUnmount(() => {
  document.removeEventListener('keyup', escClose, { passive: true })
  tl.kill()
})
</script>

<style lang="scss">
.header-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 55px 30px;
  z-index: 10;
  background-color: transparent;
  @include transition;

  &.alt {
    background-color: $white;

    .header-wrapper {
      .hamburger {
        background-color: $light-gray;
      }

      .logo-wrapper a img {
        &.default {
          display: none;
        }

        &.alt {
          display: block;
        }
      }
    }
  }

  @include mobile {
    padding: 10px 12px;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;

    .logo-wrapper {
      position: relative;

      a {
        display: block;
        visibility: hidden;

        &.visible {
          visibility: visible;
        }

        img {
          width: 260px;

          @include tablet-landscape(1) {
            width: 200px;
          }

          &.alt {
            display: none;
          }

          @include mobile {
            width: 150px;
          }
        }
      }
    }

    .nav-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      background-color: $light-gray;

      .nav-head {
        padding: 40px 55px 30px;

        @include mobile {
          padding: 10px 12px;
        }

        .logo {
          a {
            display: block;

            img {
              width: 261px;

              @include tablet-landscape(1) {
                width: 200px;
              }

              @include mobile {
                width: 150px;
              }
            }
          }
        }
      }

      nav {
        border-top: 1px solid $charcoal;

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            display: flex;
            align-items: center;
            width: 33.33%;
            padding: 50px;

            @media only screen and (max-width: 1200px) {
              padding: 40px;
            }

            @include tablet-landscape(1) {
              width: 100%;
              padding: 7px;
            }
          }
        }
      }

      .nav-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 35px 120px;
        border-top: 2px solid $orange;

        @include mobile {
          flex-direction: column;
          align-items: flex-start;
          padding: 30px;
        }

        .address {
          color: $orange;

          @include mobile {
            margin: 0 0 20px;
          }
        }

        .socials {
          display: flex;
          align-items: center;

          a {
            display: block;
            margin-right: 16px;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    .hamburger {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      z-index: 11;
      background: rgba(243, 239, 237, 0.8);
      border: 1px solid $white;
      @include transition;

      @include mobile {
        width: 50px;
        height: 50px;
      }

      &.open {
        background-color: transparent;
        border-color: $orange;

        span {
          &:first-child,
          &:last-child {
            top: 50%;
            left: 50%;
            width: 30px;

            @include mobile {
              width: 25px;
            }
          }

          &:first-child {
            transform: translate(-50%) rotate(45deg);
          }

          &:nth-child(2) {
            width: 0;
          }

          &:last-child {
            transform: translate(-50%) rotate(-45deg);
          }
        }
      }

      .hamburger-inner {
        position: relative;
        width: 30px;
        height: 30px;

        @include mobile {
          width: 25px;
          height: 20px;
        }
      }

      span {
        position: absolute;
        display: block;
        left: 0;
        height: 2px;
        background-color: $orange;
        @include transition;

        &:first-child {
          top: 0;
          width: 22px;

          @include mobile {
            width: 18px;
          }
        }

        &:nth-child(2) {
          top: 50%;
          width: 30px;
          transform: translateY(-50%);

          @include mobile {
            width: 25px;
          }
        }

        &:last-child {
          top: calc(100% - 2px);
          width: 14px;
        }
      }
    }
  }
}
</style>
